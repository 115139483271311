import { Suspense, lazy } from 'react'
import { useAuth } from './auth-context'

import { Spinner } from 'react-bootstrap'

const StaffApp = lazy(() => import('./staff-app'))
const LoginApp = lazy(() => import('./login-app'))

function App() {
	//<Route exact path="/login" component={() => <Login appState={appState}/>} />
	// const [verified, setVerified] = useState(false);
	// useEffect(() => {
	// 	(async () => {
	// 		let accessToken = localStorage.getItem("accessToken");
	// 		console.log(accessToken)
	// 		if (accessToken) {
	// 		  try {
	// 			  const headers = {
	// 				  'auth-token': accessToken,
	// 				}
	// 			const res = await api.dashboard(headers)
	// 			setVerified(true)
	// 			console.log(res.data)
	// 			console.log("verified true")

	// 		  } catch (error) {
	// 			setVerified(false)
	// 			console.error(error);
	// 			console.log("verified false")

	// 		  }
	// 		}
	// 		console.log(verified)
	// 	})();
	// }, []);

	const spinner = (
		<div style={{
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			textAlign: "center",
			minHeight: "100vh"
		}}>
			<Spinner animation="border" role="status">
				<span className="sr-only">Loading...</span>
			</Spinner>
			<span>Loading...</span>
		</div >
	)

	const props = useAuth()
	return (
		<Suspense fallback={spinner}>
			{props.user ? <StaffApp props={props} /> : <LoginApp />}
		</Suspense>
	);
}

export default App;
