import axios from "axios";

const baseUrl = "https://backend1.sutdsh.xyz";
//const baseUrl = "http://localhost:3025";

//request interceptor to add the auth token header to requests
axios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers["auth-token"] = accessToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return new Promise((resolve, reject) => {
      const originalRequest = error.config
      const refreshToken = localStorage.getItem('refreshToken')
      if (error.response && error.response.status === 400 && error.config && !error.config.__isRetryRequest && refreshToken) {
        originalRequest.__isRetryRequest = true
        const response = api.refresh(
        ).then((res) => {
          return axios(originalRequest)
        }).catch((err) => {
          return Promise.reject(err)
        })
        resolve(response)
      }
      else {
        reject(error)
      }

    })

  },
);

//functions to make api calls
const api = {
  register: (body) => {
    return axios.post(`${baseUrl}/api/user/register`, body);
  },
  login: (body) => {
    return axios.post(`${baseUrl}/api/user/login`, body);
  },
  refresh: () => {
    return new Promise((resolve, reject) => {
      const refresh = localStorage.getItem("refreshToken")
      axios.post(`${baseUrl}/api/user/token`, { refresh: refresh }).then((res) => {
        localStorage.setItem("accessToken", res.data.token)
        localStorage.setItem("refreshToken", res.data.newrefresh)
        resolve(res)
      }).catch((err) => {
        localStorage.removeItem("accessToken")
        localStorage.removeItem("refreshToken")
        reject(err)
      })
    })
  },
  dashboard: () => {
    return axios.post(`${baseUrl}/api/dashboard`, "");
  },
  logout: (body) => {
    localStorage.removeItem("accessToken")
    localStorage.removeItem("refreshToken")
    return axios.post(`${baseUrl}/api/user/logout`, body);
  },
  changeName: (body) => {
    return axios.post(`${baseUrl}/api/user/changename`, body);
  },
  changePassword: (body) => {
    return axios.post(`${baseUrl}/api/user/changepassword`, body);
  },
  submitAnnouncement: (body) => {
    return axios.post(`${baseUrl}/announcement`, body)
  },
  getTenants: () => {
    return axios.post(`${baseUrl}/api/user/listTenants`)
  },
  submitChecklist: (body) => {
    return axios.post(`${baseUrl}/storeChecklist/store`, body)
  },
  getMessages: (body) => {
    return axios.post(`${baseUrl}/conversation/retrieve`, body)
  },
  // getChecklistData:(body) => {
  //   return axios.post(`${baseUrl}/storeChecklist/retrieveByChecklist`, body)
  // },
  getChecklistData: (body) => {
    return axios.post(`${baseUrl}/retrieveChecklistByInstitution/retrieveByChecklist`, body)
  },
  getChecklistByInstitution: (body) => {
    return axios.post(`${baseUrl}/retrieveChecklistByInstitution/retrieveByInstitution`, body)
  },
  getTenantByInstitution: (body) => {
    return axios.post(`${baseUrl}/retrieveChecklistByInstitution/listOfTenants`, body)
  },
  submitNonCompliance: (body) => {
    return axios.post(`${baseUrl}/nonCompliance/create`, body)
  },
  sendMessage: (body) => {
    let axiosArr = []

    for (let i of body) {
      let newPromise = axios.post(`${baseUrl}/conversation/store`, i);
      axiosArr.push(newPromise);
    }

    return axios.all(axiosArr).then(axios.spread((...responses) => {
      responses.forEach(res => console.log('Success'))
      console.log('submitted all axios calls');
    }))
      .catch(error => { console.log(error) })
  },
  getAllNonCompliance: () => {
    return axios.post(`${baseUrl}/nonCompliance/retrieveAll`)
  },
  getAllOpenNonCompliance: () => {
    return axios.post(`${baseUrl}/nonCompliance/retrieveAllOpen`)
  },
  getNonComplianceByChecklist: (body) => {
    return axios.post(`${baseUrl}/nonCompliance/retrieveByChecklist`, body)
  },
  getNonComplianceByStatus: (body) => {
    console.log("API");
    console.log(body)
    return axios.post(`${baseUrl}/nonCompliance/retrieveByStatus`, body)
  },
  // Remember to await this function!
  getNonComplianceUnreadNotif: (body, name) => {
    return axios.post(`${baseUrl}/nonCompliance/retrieveUnreadNotif`, body).then((res) => {
      for (const i of res.data) {
        if (i.name === name) {
          return i.numUnread;
        }
      }
    })
  },
  // Note that the sign of "change" is important!!
  updateNonComplianceUnreadNotif: (body) => {
    return axios.post(`${baseUrl}/nonCompliance/updateUnreadNotif`, body)
  },
  // Close NonCompliance
  closeNonCompliance: (body) => {
    return axios.post(`${baseUrl}/nonCompliance/close`, body)
  },
};
export default api;