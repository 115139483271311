import api from "./api"
import { useState, useEffect, useContext, createContext} from "react";

async function bootstrapAppData(setWaiting,setUserInfo) {
    let user = null
    let role = null
    let institution = null
    let id = null
    let accessToken = localStorage.getItem("accessToken")
    if (accessToken) {
        try {
          const res = await api.dashboard()
          user = res.data.data.user.name
          role = res.data.data.user.role
          institution = res.data.data.user.institution_code
          id = res.data.data.user.id
        } catch (error) {
          console.error(error)
        }
    }
    setUserInfo({ user: user, role: role, institution: institution, id: id })
    setWaiting(false)
  }
const AuthContext = createContext()
function AuthProvider(props) {
    // code for pre-loading the user's information if we have their token in
    // localStorage goes here
    const [waiting, setWaiting] = useState(false);
    const [userinfo, setUserInfo] = useState({user:null, role:null, institution:null, id:null});
    useEffect(() => {
        setWaiting(true)
        bootstrapAppData(setWaiting,setUserInfo)
    }, [])
    // 🚨 this is the important bit.
    // Normally your provider components render the context provider with a value.
    // But we post-pone rendering any of the children until after we've determined
    // whether or not we have a user token and if we do, then we render a spinner
    // while we go retrieve that user's information.
    if (waiting) {
      return <div>loading</div>
    }
    const user = userinfo.user
    const role = userinfo.role
    const institution = userinfo.institution
    const id = userinfo.id
    return (
      <AuthContext.Provider value={{user , role, institution, id}} {...props} />
    )
  }
  const useAuth = () => {
      const context = useContext(AuthContext)
      if (context === undefined) {
        throw new Error(`useAuth must be used within a AuthProvider`)
      }
      return context
  }
  export {AuthProvider, useAuth}